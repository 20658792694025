<template>
  <div class="center-box">
    <p>이용자 구분에 문제가 있습니다.</p>
    <p>- 스쿨투게더 -</p>
    <p>02. 499. 9725</p>
  </div>
</template>

<script>
export default {
  name: "NonUser",
};
</script>

<style lang="scss">
@import "~@/color";

.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 1.6rem;
  color: $gray06;

  p:nth-child(2) {
    font-size: 1.4rem;
    color: $violet01;
  }

  p + p {
    margin-top: 10px;
  }
}
</style>
